import { useTranslation } from 'react-i18next';
import { useDeletePeriod } from 'src/apis/exchangeRateAPI';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'src/components/mui-components';
import { Dispatch, FC, SetStateAction } from 'react';
import { handleError } from '../../utils/serverResponseHandler';

interface DeletePeriodModalProps {
  periodId: number;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPeriodId: Dispatch<SetStateAction<number | undefined>>;
}

const DeletePeriodModal: FC<DeletePeriodModalProps> = ({
  periodId,
  isOpen,
  setIsOpen,
  setSelectedPeriodId,
}) => {
  const { mutateAsync: deletePeriod } = useDeletePeriod();
  const { t } = useTranslation('systemAdminExchangeRate');

  const deleteAction = async () => {
    try {
      const response = await deletePeriod(periodId, {
        onError: (error) => {
          handleError(error, t);
        },
      });
      if (response) {
        setSelectedPeriodId(undefined);
        return response;
      }
    } catch (e) {
      // TODO: We should notify the user that an error has occurred
      // for now we are just logging the error as we don't have a reasonable way to
      // tell what went wrong.
      // eslint-disable-next-line no-console
      console.error('Error deleting period', e);
    } finally {
      setIsOpen(false);
    }
    return null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="dialog-delete-period"
      aria-describedby="dialog-delete-period-desc"
    >
      <DialogTitle id="dialog-delete-period"> {t('modals.DeletePeriodTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-delete-period-desc">
          {t('modals.DeletePeriodDescription')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          data-automation-id="DeletePeriodModalCancelButton"
          onClick={() => setIsOpen(false)}
        >
          {t('modals.CancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => deleteAction()}
          autoFocus
          data-automation-id="DeletePeriodModalSaveButton"
        >
          {t('modals.DeletePeriodSaveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePeriodModal;
