import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { ISearchFilter, TFilterNames } from '../types';

export const useGetFlattenedFilterItems = (filterEnum: Partial<Record<TFilterNames, string>>) => {
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList } = useGetFilterAPI(pageIdentifier);
  const flattenedFilterItems = filterList.reduce(
    (a, { filterItems }) => ({
      ...a,
      ...(filterItems ?? []).reduce(
        (b, c) => ({
          ...b,
          [c.name]: {
            contentUrl: c.contentUrl,
            filterName: c.name,
            id: c.id,
          },
        }),
        {},
      ),
    }),
    {} as Record<TFilterNames, ISearchFilter>,
  );

  return Object.keys(filterEnum)
    .reduce((a, b): ISearchFilter[] => {
      const filterName = b as keyof typeof filterEnum;
      return [...a, flattenedFilterItems[filterName]];
    }, [] as ISearchFilter[])
    .filter((i) => i);
};
