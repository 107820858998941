import { useQuery } from '@tanstack/react-query';
import { IFilterCompetence } from 'src/screens/ResourcePlanner/components/AssignFlow/components/FilterItems/FilterItemCompetence';
import { getApiDateFormat } from 'src/utils/date/date';
import { postFetch } from 'src/utils/fetcher';
import { IFilter } from 'src/screens/ResourcePlanner/components/AssignFlow/types';
import { POST_EMPLOYEE_SEARCH_KEY } from './postEmployeeSearchAPI.constants';
import {
  TEmployeeSearchReq,
  TEmployeeSearchReqCompetence,
  TEmployeeSearchReqDepartment,
  TEmployeeSearchReqLegalEntity,
  TEmployeeSearchReqManager,
  TEmployeeSearchRes,
} from './postEmployeeSearchAPI.types';

const POST_EMPLOYEE_SEARCH_URL_PATH = '/api/v2/resource-planner/employee-search';

const postEmployeeSearch = (reqBody: TEmployeeSearchReq): Promise<TEmployeeSearchRes> =>
  postFetch({
    path: POST_EMPLOYEE_SEARCH_URL_PATH,
    key: POST_EMPLOYEE_SEARCH_KEY,
    body: JSON.stringify(reqBody),
  });

interface IUsePostEmployeeSearch {
  taskId: string;
  competence?: IFilterCompetence;
  department?: IFilter;
  endDate?: Date | null;
  hours?: number;
  legalEntity?: IFilter;
  manager?: IFilter;
  query?: string;
  startDate?: Date | null;
}

export const usePostEmployeeSearch = (
  {
    competence,
    department,
    endDate,
    hours,
    legalEntity,
    manager,
    query,
    startDate,
    taskId,
  }: IUsePostEmployeeSearch,
  enableQuery?: boolean,
) => {
  const Manager: TEmployeeSearchReqManager = manager?.values?.length
    ? {
        ManagerIds: manager.values.map((m) => m.value).toString(),
        IsManagerInclusive: manager.isInclude,
      }
    : {
        ManagerIds: undefined,
        IsManagerInclusive: undefined,
      };

  const Department: TEmployeeSearchReqDepartment = department?.values?.length
    ? {
        DepartmentIds: department.values.map((d) => d.value).toString(),
        IsDepartmentInclusive: department.isInclude,
      }
    : {
        DepartmentIds: undefined,
        IsDepartmentInclusive: undefined,
      };

  const LegalEntity: TEmployeeSearchReqLegalEntity = legalEntity?.values?.length
    ? {
        LegalEntityIds: legalEntity.values.map((l) => l.value).toString(),
        IsLegalEntityInclusive: legalEntity.isInclude,
      }
    : {
        LegalEntityIds: undefined,
        IsLegalEntityInclusive: undefined,
      };

  const Competence: TEmployeeSearchReqCompetence = competence?.values?.length
    ? {
        Competences: competence.values.map((c) => ({ Id: c.value, Value: c.levels.toString() })),
        IsCompetenceInclusive: true,
      }
    : {
        Competences: undefined,
        IsCompetenceInclusive: undefined,
      };

  const reqBody: TEmployeeSearchReq = {
    Hours: hours,
    PeriodEndDate: getApiDateFormat(endDate ?? new Date()),
    PeriodStartDate: getApiDateFormat(startDate ?? new Date()),
    SearchText: query?.trim(),
    TaskId: Number(taskId),
    ...Competence,
    ...Department,
    ...LegalEntity,
    ...Manager,
  };

  const filteredReqBody = Object.keys(reqBody).reduce((a, b) => {
    const key = b as keyof TEmployeeSearchReq;
    if (reqBody[key] !== undefined && reqBody[key] !== null && reqBody[key] !== '') {
      return { ...a, [key]: reqBody[key] };
    }
    return a;
  }, {} as TEmployeeSearchReq);

  const { data, ...rest } = useQuery(
    [POST_EMPLOYEE_SEARCH_KEY, filteredReqBody],
    () => postEmployeeSearch(filteredReqBody),
    { enabled: enableQuery },
  );

  return { data: data?.entities, ...rest };
};
