import { IFilter, TFilterNames } from '../../types';

/**
 * Filter names for task search.
 *
 * Arranged in the order of appearance in the UI.
 */
export enum EResourceFilterNames {
  LegalEntityProject = 'LegalEntityProject',
  ProjectDepartment = 'ProjectDepartment',
  ApprovalManager = 'ApprovalManager',
}

export type TResourceFilterNames = keyof typeof EResourceFilterNames;

export type TResourceDefaultFilters = Pick<Record<TFilterNames, IFilter>, TResourceFilterNames>;
