import { useQuery } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { IFilter } from 'src/screens/ResourcePlanner/components/AssignFlow/types';
import { POST_PROJECT_SEARCH_KEY } from './postProjectSearchAPI.constants';
import {
  TProjectSearchReq,
  TProjectSearchReqCustomer,
  TProjectSearchReqDepartment,
  TProjectSearchReqLegalEntity,
  TProjectSearchReqProjectManager,
  TProjectSearchReqProjectType,
  TProjectSearchRes,
} from './postProjectSearchAPI.types';

const POST_PROJECT_SEARCH_URL_PATH = '/api/v2/resource-planner/project-search';

const postProjectSearch = (reqBody: TProjectSearchReq): Promise<TProjectSearchRes> =>
  postFetch({
    path: POST_PROJECT_SEARCH_URL_PATH,
    key: POST_PROJECT_SEARCH_KEY,
    body: JSON.stringify(reqBody),
  });

interface IUsePostProjectSearch {
  query: string;
  customer?: IFilter;
  department?: IFilter;
  legalEntity?: IFilter;
  projectManager?: IFilter;
  projectType?: IFilter;
}

export const usePostProjectSearch = (
  {
    customer: c,
    department: d,
    legalEntity: le,
    projectManager: pm,
    projectType: pt,
    query,
  }: IUsePostProjectSearch,
  enableQuery?: boolean,
) => {
  const customer: TProjectSearchReqCustomer = c?.values?.length
    ? {
        CustomerIds: c.values.map(({ value }) => value).toString(),
        IsCustomerInclusive: c.isInclude,
      }
    : {
        CustomerIds: undefined,
        IsCustomerInclusive: undefined,
      };

  const department: TProjectSearchReqDepartment = d?.values?.length
    ? {
        DepartmentIds: d.values.map(({ value }) => value).toString(),
        IsDepartmentInclusive: d.isInclude,
      }
    : {
        DepartmentIds: undefined,
        IsDepartmentInclusive: undefined,
      };

  const legalEntity: TProjectSearchReqLegalEntity = le?.values?.length
    ? {
        LegalEntityIds: le.values.map(({ value }) => value).toString(),
        IsLegalEntityInclusive: le.isInclude,
      }
    : {
        LegalEntityIds: undefined,
        IsLegalEntityInclusive: undefined,
      };

  const projectManager: TProjectSearchReqProjectManager = pm?.values?.length
    ? {
        ProjectManagerIds: pm.values.map(({ value }) => value).toString(),
        IsProjectManagerInclusive: pm.isInclude,
      }
    : {
        ProjectManagerIds: undefined,
        IsProjectManagerInclusive: undefined,
      };

  const projectType: TProjectSearchReqProjectType = pt?.values?.length
    ? {
        ProjectTypeIds: pt.values.map(({ value }) => value).toString(),
        IsProjectTypeInclusive: pt.isInclude,
      }
    : {
        ProjectTypeIds: undefined,
        IsProjectTypeInclusive: undefined,
      };

  const reqBody: TProjectSearchReq = {
    SearchText: query.trim(),
    ...customer,
    ...department,
    ...legalEntity,
    ...projectManager,
    ...projectType,
  };

  const filteredReqBody = Object.keys(reqBody).reduce((a, b) => {
    const key = b as keyof TProjectSearchReq;
    if (reqBody[key] !== undefined && reqBody[key] !== null && reqBody[key] !== '') {
      return { ...a, [key]: reqBody[key] };
    }
    return a;
  }, {} as TProjectSearchReq);

  const { data, ...rest } = useQuery(
    [POST_PROJECT_SEARCH_KEY, filteredReqBody],
    () => postProjectSearch(filteredReqBody),
    { enabled: enableQuery },
  );

  return { data: data?.entities, ...rest };
};
