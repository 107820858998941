import { Add, ArrowBack, MoreVert, Remove } from '@mui/icons-material';
import { Container, Rating, List, ListItem, Stack, Menu, MenuItem, Button } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetEmployeeCompetences,
  useUpdateEmployeeCompetence,
  useDeleteEmployeeCompetence,
  useDeleteAllEmployeeCompetence,
} from 'src/apis/employeeCompetencesAPI';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { IconButton, ToastifyAlert, Typography } from 'src/components/mui-components';
import { Deck, LinkAsButton } from 'src/components/ui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import PageRoute from 'src/routers/PageRoute';
import {
  NewCompetenceCategoryDialog,
  NewCompetenceDialog,
} from 'src/screens/SystemAdministration/Employees/Competences/components';
import { Trans, useTranslation } from 'react-i18next';
import grays from 'src/assets/styles/variables/colors/global/grays.module.scss';
import { useGetUser } from 'src/apis/userAPI';
import { TCompetenceDialogType } from 'src/screens/SystemAdministration/Employees/Competences/components/types';
import { useGoBack } from 'src/hooks/useGoBack';
import { AddCompetenceDialog, RemoveCompetenceDialog } from './components';
import { ICompetenceToRemove } from './types';

export const Competences = () => {
  const { t } = useTranslation('employeeCompetences');

  // Data
  const { id: userId } = useParams();
  const { competencesList, isLoading } = useGetEmployeeCompetences(userId);
  const { fullNameWithInitials } = useGetUser(userId);

  const { mutate: updateCompetence, isLoading: isUpdating } = useUpdateEmployeeCompetence();
  const { mutate: deleteCompetence } = useDeleteEmployeeCompetence();
  const { mutate: deleteAllCompetences } = useDeleteAllEmployeeCompetence();
  const [competenceToRemove, setCompetenceToRemove] = useState<ICompetenceToRemove>();
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuIsOpen = Boolean(contextMenuAnchorEl);
  const handleContextMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setContextMenuAnchorEl(event.currentTarget);
  };
  const handleContextMenuClose = () => {
    setContextMenuAnchorEl(null);
  };

  // Dialogs
  const [dialogIsOpen, setDialogIsOpen] = useState<TCompetenceDialogType | null>(null);
  const handleDialogClose = () => setDialogIsOpen(null);

  const handleRemoveCompetenceDialogOpen = ({
    name,
    id,
    type = 'competence',
  }: ICompetenceToRemove) => {
    setCompetenceToRemove({ name, id, type });
    setDialogIsOpen('remove');
    handleContextMenuClose();
  };

  const handleRemoveCompetenceDialogClose = () => {
    handleDialogClose();
    setCompetenceToRemove({});
  };

  const handleCreateCompetenceDialogOpen = () => {
    setDialogIsOpen('create');
    handleContextMenuClose();
  };

  const handleCreateCompetenceCategoryDialogOpen = () => {
    setDialogIsOpen('createCategory');
    handleContextMenuClose();
  };

  // Actions
  const removeCompetenceHandler = ({ id, name }: Omit<ICompetenceToRemove, 'type'>) => {
    deleteCompetence(id, {
      onSuccess: () => {
        handleDialogClose();
        toast(
          <ToastifyAlert
            title={t('modals.RemoveItemToastTitle', { ITEM_NAME: name })}
            color="success"
            data-automation-id="EmployeeCompetenceRemoveToastSuccess"
          />,
          {
            autoClose: 2000,
            closeButton: false,
          },
        );
      },
      onError: (error) => {
        toast(
          <ToastifyAlert
            title={t('modals.RemoveItemToastTitleError', { ITEM_NAME: name })}
            description={`${error}`}
            color="error"
            data-automation-id="EmployeeCompetenceRemoveToastError"
          />,
          {
            closeButton: true,
          },
        );
      },
    });
  };

  const removeCategoryHandler = ({ id, name }: Omit<ICompetenceToRemove, 'type'>) => {
    deleteAllCompetences(
      { userId: parseInt(userId ?? '0', 10), groupId: id },
      {
        onSuccess: () => {
          handleDialogClose();
          toast(
            <ToastifyAlert
              title={t('modals.RemoveItemToastTitle', { ITEM_NAME: name })}
              color="success"
              data-automation-id="EmployeeCompetenceRemoveToastSuccess"
            />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('modals.RemoveItemToastTitleError', { ITEM_NAME: name })}
              description={`${error}`}
              color="error"
              data-automation-id="EmployeeCompetenceRemoveToastError"
            />,
            {
              closeButton: true,
            },
          );
        },
      },
    );
  };

  const removeAllHandler = () => {
    deleteAllCompetences(
      { userId: parseInt(userId ?? '0', 10) },
      {
        onSuccess: () => {
          handleDialogClose();
          toast(
            <ToastifyAlert
              title={t('RemoveAllCompetencesToastTitle')}
              color="success"
              data-automation-id="EmployeeCompetenceRemoveToastSuccess"
            />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('RemoveAllCompetencesToastTitleError')}
              description={`${error}`}
              color="error"
              data-automation-id="EmployeeCompetenceRemoveToastError"
            />,
            {
              closeButton: true,
            },
          );
        },
      },
    );
  };

  const remove = (item?: ICompetenceToRemove) => {
    switch (item?.type) {
      case 'category':
        removeCategoryHandler({ id: item.id, name: item.name });
        break;
      case 'competence':
        removeCompetenceHandler({ id: item.id, name: item.name });
        break;
      default:
        removeAllHandler();
        break;
    }
  };

  const handleLevelChange = (newValue: number | null, name?: string, competenceId?: number) => {
    if (newValue === null) return;

    updateCompetence(
      { competenceId, value: newValue },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('ToastLevelChangeTitleSuccess', { COMPETENCE_NAME: name })}
              description={t('ToastLevelChangeDescriptionSuccess', {
                NEW_LEVEL: newValue,
              })}
              color="success"
              data-automation-id="EmployeeCompetenceLevelChangeToastSuccess"
            />,
            { autoClose: 4000, closeButton: false },
          );
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('ToastLevelChangeTitleError', { COMPETENCE_NAME: name })}
              description={`${error}`}
              color="error"
              data-automation-id="EmployeeCompetenceLevelChangeToastError"
            />,
            {
              closeButton: true,
            },
          );
        },
      },
    );
  };

  const goBack = useGoBack();

  return (
    <>
      <ScreenHeader />
      <PageRoute
        pageTitle={fullNameWithInitials}
        pageId="dc23305c-5d92-49ab-b67e-6e356218ff0b"
        pageIdentifier="DesignSevenEditEmployeeCompetence"
      >
        <Deck>
          <Deck.Item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ displayPrint: 'none' }}
            >
              <Button
                onClick={() => goBack({ fallback: '/Employee/EmployeeOverview/Index' })}
                startIcon={<ArrowBack />}
                data-automation-id="EmployeeCompetenceBackButton"
              >
                {t('BackButtonText')}
              </Button>
            </Stack>

            {/* ml: 0 is to align it to the left */}
            <Container maxWidth="sm" disableGutters sx={{ ml: 0 }}>
              <Stack gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="h2" fontWeight={500}>
                    {t('SectionHeader')}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      color="primary"
                      title={t('AddCompetenceButtonTooltipText', {
                        EMPLOYEE_NAME: fullNameWithInitials ?? '',
                      })}
                      onClick={() => setDialogIsOpen('add')}
                      data-automation-id="EmployeeCompetenceAddCompetenceButton"
                      sx={{ displayPrint: 'none' }}
                    >
                      <Add />
                    </IconButton>

                    <IconButton
                      title={t('MoreActionsTooltipText')}
                      id="competence-context-menu-button"
                      aria-controls="competence-context-menu"
                      aria-haspopup="true"
                      aria-expanded={contextMenuIsOpen ? 'true' : 'false'}
                      onClick={handleContextMenuClick}
                      data-automation-id="EmployeeCompetenceContextMenuButton"
                      sx={{ displayPrint: 'none' }}
                    >
                      <MoreVert />
                    </IconButton>

                    <Menu
                      id="competence-context-menu"
                      anchorEl={contextMenuAnchorEl}
                      open={contextMenuIsOpen}
                      onClose={handleContextMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'competence-context-menu-button',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem
                        onClick={handleCreateCompetenceDialogOpen}
                        data-automation-id="EmployeeCompetenceCreateNewCompetenceButton"
                      >
                        {t('CreateNewCompetenceButtonText')}
                      </MenuItem>
                      <MenuItem
                        onClick={handleCreateCompetenceCategoryDialogOpen}
                        data-automation-id="EmployeeCompetenceCreateNewCategoryButton"
                      >
                        {t('CreateNewCategoryButtonText')}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleRemoveCompetenceDialogOpen({
                            type: 'all',
                          })
                        }
                        data-automation-id="EmployeeCompetenceRemoveAllCompetencesButton"
                        disabled={competencesList?.length === 0}
                      >
                        {t('RemoveAllCompetencesButtonText')}
                      </MenuItem>
                    </Menu>
                  </Stack>
                </Stack>
                <Stack alignItems="start">
                  <ResponseHandler
                    isLoading={isLoading}
                    isEmpty={competencesList?.length === 0}
                    EmptyComponent={
                      <Stack gap={2}>
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                          <Trans
                            i18nKey="NoCompetencesAddedToEmployeeContent"
                            key="NoCompetencesAddedToEmployeeContent"
                            defaults={t('NoCompetencesAddedToEmployeeContent')}
                            components={[
                              <LinkAsButton
                                onClick={() => setDialogIsOpen('add')}
                                aria-label={t('AddCompetenceButtonTooltipText', {
                                  EMPLOYEE_NAME: fullNameWithInitials ?? '',
                                })}
                              />,
                            ]}
                          />
                        </Typography>
                      </Stack>
                    }
                  >
                    <Stack component={List} gap={1} disablePadding>
                      {competencesList?.map(({ groupId, groupName, items }) => (
                        <Stack
                          gap={0}
                          component={ListItem}
                          key={groupId}
                          disableGutters
                          alignItems="stretch"
                          sx={{
                            // This is to divide to the next category
                            borderBottom: `1px solid ${grays.colorGray20}`,
                          }}
                        >
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h3" fontWeight={600}>
                              {groupName}
                            </Typography>
                            <IconButton
                              title={t('RemoveCategoryButtonText', { CATEGORY_NAME: groupName })}
                              onClick={() =>
                                handleRemoveCompetenceDialogOpen({
                                  name: groupName,
                                  id: groupId,
                                  type: 'category',
                                })
                              }
                              data-automation-id={`EmployeeCompetenceRemoveCategoryButton${groupId}`}
                              sx={{ displayPrint: 'none' }}
                            >
                              <Remove fontSize="small" />
                            </IconButton>
                          </Stack>
                          <List>
                            {items?.map(({ id: itemId, name, value }) => (
                              <ListItem key={itemId} disableGutters>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ width: '100%' }}
                                  gap={2}
                                >
                                  <Typography variant="body2">{name}</Typography>
                                  <Stack direction="row" alignItems="center">
                                    <Rating
                                      name={`rating-${groupId}-${itemId}`}
                                      value={value}
                                      getLabelText={() => `${name}, value: ${value}`}
                                      onChange={(event, newValue) =>
                                        handleLevelChange(newValue, name, itemId)
                                      }
                                      data-automation-id={`EmployeeCompetenceRating${groupId}${itemId}`}
                                      readOnly={isUpdating}
                                    />
                                    <IconButton
                                      title={t('RemoveCompetenceButtonText', {
                                        COMPETENCE_NAME: name,
                                      })}
                                      onClick={() =>
                                        handleRemoveCompetenceDialogOpen({ name, id: itemId })
                                      }
                                      data-automation-id={`EmployeeCompetenceRemoveCompetenceButton${groupId}${itemId}`}
                                      sx={{ displayPrint: 'none' }}
                                    >
                                      <Remove fontSize="small" />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </ListItem>
                            ))}
                          </List>
                        </Stack>
                      ))}
                    </Stack>
                  </ResponseHandler>
                </Stack>
              </Stack>
            </Container>
          </Deck.Item>
        </Deck>
      </PageRoute>

      {/* Dialogs */}
      {dialogIsOpen === 'add' ? (
        <AddCompetenceDialog
          fullWidth
          maxWidth="sm"
          open={dialogIsOpen === 'add'}
          closeDialog={handleDialogClose}
          userId={userId}
        />
      ) : null}

      {dialogIsOpen === 'create' ? (
        <NewCompetenceDialog
          fullWidth
          maxWidth="sm"
          open={dialogIsOpen === 'create'}
          onClose={handleDialogClose}
          closeDialog={handleDialogClose}
        />
      ) : null}

      {dialogIsOpen === 'createCategory' ? (
        <NewCompetenceCategoryDialog
          fullWidth
          maxWidth="sm"
          open={dialogIsOpen === 'createCategory'}
          onClose={handleDialogClose}
          closeDialog={handleDialogClose}
        />
      ) : null}

      {dialogIsOpen === 'remove' ? (
        <RemoveCompetenceDialog
          dialogIsOpen={dialogIsOpen}
          handleRemoveCompetenceDialogClose={handleRemoveCompetenceDialogClose}
          competenceToRemove={competenceToRemove}
          remove={remove}
        />
      ) : null}
    </>
  );
};
