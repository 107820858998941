import { useTranslation } from 'react-i18next';
import { useDeleteProject } from 'src/apis/projectsAPI';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToastifyAlert,
} from 'src/components/mui-components';
import { CircularProgress } from '@mui/material';

import { toast } from 'react-toastify';
import { Dispatch, FC, SetStateAction } from 'react';
import { handleError, IError } from '../../utils/serverResponseHandler';

interface DeleteProjectModalProps {
  projectId: string;
  showProjectDelete: boolean;
  setShowProjectDelete: Dispatch<SetStateAction<boolean>>;
  selectedProjectsId: [
    {
      projectId: string;
    },
  ];
  applyProjectsUpdate: (uri?: string, validDate?: Date) => void;
}

export const ProjectDeleteDialog: FC<DeleteProjectModalProps> = ({
  projectId,
  showProjectDelete,
  setShowProjectDelete,
  selectedProjectsId,
  applyProjectsUpdate,
}) => {
  const { mutateAsync: deleteProject, isLoading } = useDeleteProject();
  const { t } = useTranslation('searchProjects');

  const deleteSingleProject = async () => {
    try {
      await deleteProject(projectId, {
        onError: (error: unknown) => {
          handleError(error as IError, t);
        },
        onSuccess: () => {
          toast.success(<ToastifyAlert description={t('ToastProjectDeletedText')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
      });
    } catch (error) {
      // TODO: We should notify the user that an error has occurred
      // for now we are just logging the error as we don't have a reasonable way to
      // tell what went wrong.
      // eslint-disable-next-line no-console
      console.error('Error deleting project:', error);
    }
  };

  const handleDelete = () => {
    if (projectId) {
      // Single project deletion logic
      deleteSingleProject();
    } else if (selectedProjectsId && selectedProjectsId.length > 0) {
      // Multiple projects deletion logic
      applyProjectsUpdate('delete');
    }
    setShowProjectDelete(false);
  };

  return (
    <Dialog
      open={showProjectDelete}
      onClose={() => setShowProjectDelete(false)}
      aria-labelledby="dialog-delete-project"
      aria-describedby="dialog-delete-project-desc"
    >
      <DialogTitle id="dialog-delete-project">{t('DeleteProjectText')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-delete-period-desc">
          {t('DeleteProjectConfirmMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          data-automation-id="DeleteProjectModalCancelButton"
          onClick={() => setShowProjectDelete(false)}
        >
          {t('CancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleDelete()}
          autoFocus
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
          data-automation-id="DeleteProjectModalSaveButton"
        >
          {t('SaveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
