import { IFilter, TFilterNames } from '../../types';

/**
 * Filter names for task search.
 *
 * Arranged in the order of appearance in the UI.
 */
export enum ETaskFilterNames {
  Customer = 'Customer',
  LegalEntityProject = 'LegalEntityProject',
  ProjectDepartment = 'ProjectDepartment',
  ProjectManager = 'ProjectManager',
  ProjectType = 'ProjectType',
}

export type TTaskFilterNames = keyof typeof ETaskFilterNames;

export type TTaskDefaultFilters = Pick<Record<TFilterNames, IFilter>, TTaskFilterNames>;
