import { parseISO } from 'date-fns';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostExchangeRateNewManualPeriod } from 'src/apis/exchangeRateAPI';
import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from 'src/components/mui-components';
import { IconButton } from 'src/components/ui-components/IconButton';
import { handleError } from '../../utils/serverResponseHandler';
import styles from './NewPeriodModal.module.scss';

interface NewPeriodModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  metaData: any;
}

const NewPeriodModal: FC<NewPeriodModalProps> = ({ isOpen, setIsOpen, metaData }) => {
  const { mutateAsync: postNewManualPeriod } = usePostExchangeRateNewManualPeriod();
  const { t } = useTranslation('systemAdminExchangeRate');
  const startDate = parseISO(metaData[0].defaultValue);
  const [endDate, setEndDate] = useState(metaData[1].defaultValue);

  const handleEndDateChange = (date: Date | null) => {
    if (date instanceof Date) {
      const offset = -date.getTimezoneOffset();
      date.setHours(Math.trunc(offset / 60), offset % 60);
    }
    setEndDate(date);
  };

  const postNewManualPeriodAction = async () => {
    try {
      const response = await postNewManualPeriod(endDate, {
        onError: (error) => {
          handleError(error, t);
        },
      });
      if (response) {
        return response;
      }
    } catch (e) {
      // TODO: We should notify the user that an error has occurred
      // for now we are just logging the error as we don't have a reasonable way to
      // tell what went wrong.
      // eslint-disable-next-line no-console
      console.error('Error creating new period', e);
    } finally {
      setIsOpen(false);
      setEndDate(null);
    }

    return null;
  };

  const setTodayDate = () => {
    handleEndDateChange(new Date());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="dialog-new-period"
      aria-describedby="dialog-new-period-desc"
    >
      <DialogTitle id="dialog-new-period">{t('modals.NewPeriodTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-new-period-desc">
          {t('modals.NewPeriodDescription')} <br />
        </DialogContentText>
        <Grid container spacing={2} alignItems="center" sx={{ mt: '0' }}>
          <Grid item xs={2}>
            <span>{t('modals.DatepickerStartDate')}</span>
          </Grid>
          <Grid item xs={3.6}>
            <DatePicker
              disabled={metaData[0].isImmutable}
              format="dd-MM-yyyy"
              value={startDate}
              slotProps={{
                textField: { size: 'small', id: 'start-date-picker' },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" sx={{ mt: '0' }}>
          <Grid item xs={2}>
            <span>{t('modals.DatepickerEndDate')}</span>
          </Grid>
          <Grid item xs={3.6}>
            <DatePicker
              disabled={metaData[1].isImmutable}
              format="dd-MM-yyyy"
              onChange={(value) => handleEndDateChange(value)}
              value={endDate || null}
              slotProps={{
                textField: { size: 'small', id: 'end-date-picker' },
              }}
              minDate={startDate}
            />
            <IconButton
              iconName="integration"
              tooltipText="integration"
              className={styles.nowButton}
              onClick={() => setTodayDate()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setIsOpen(false)}
          data-automation-id="NewPeriodModalCancelButton"
        >
          {t('modals.CancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => postNewManualPeriodAction()}
          autoFocus
          data-automation-id="NewPeriodModalSaveButton"
        >
          {t('modals.NewPeriodCreateButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewPeriodModal;
